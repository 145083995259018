import LandingPage from "../components/LandingPage";
import ReviewGrid from "../components/ReviewGrid";
import Modal from "../ui-controls/Modal";
import RecordProfile from "./RecordProfile";
import Tabs from "../ui-controls/Tabs";
import TabHeader from "../ui-controls/TabHeader";
import Posts from "./Posts";

import { useEffect, useState } from "react";
import { getRecords } from "../lib/firestore";
import {
  getFirstNElements,
  signalIncorrectNumberOfElements,
} from "../lib/types";
import { getCacheStatus } from "../lib/utils";

import { Record } from "../model/database";
import { Tab } from "../model/components";

import styles from "../styles/Home.module.css";

const RECENT_RECORDS_LIMIT = 3;
const PERMANENT_SORT_OPTIONS = [
  { text: "Name", value: "name" },
  { text: "Artist", value: "artists" },
  { text: "Year", value: "release_year" },
];
const SORT_OPTIONS_WITH_RATING = [
  { text: "Rating", value: "rating" },
  ...PERMANENT_SORT_OPTIONS,
];

function Home() {
  const [reviews, setReviews] = useState([] as Array<Record>);
  const [unreviewed, setUnreviewed] = useState([] as Array<Record>);
  const [selectedRecord, setSelectedRecord] = useState({} as Record);
  const [recentReviews, setRecentReviews] = useState([] as Record[]);
  const [showModal, setShowModal] = useState(false);
  const [tabs, setTabs] = useState([] as Tab[]);

  const handleHideModal = (e: Event) => {
    const closeRegExp = /(Modal_mask)|(mdi-close)/g;
    if (closeRegExp.test((e.target as HTMLElement).className)) {
      setShowModal(false);
    }
  };

  const onSelectRecord = (record: Record) => {
    if (!record.review) return;
    setShowModal(true);
    setSelectedRecord(record);
  };

  function setSelectedTab(id: string) {
    setTabs(
      tabs.map((tab) => ({
        ...tab,
        active: tab.id === id,
      }))
    );
  }

  useEffect(() => {
    async function setDataAndCache() {
      const promiseReviewed = getRecords(true).then((reviewedRecords) => {
        setReviews(reviewedRecords);
        const recentReviews = reviewedRecords.filter((record) => record.recent);
        signalIncorrectNumberOfElements(recentReviews, RECENT_RECORDS_LIMIT);
        setRecentReviews(
          getFirstNElements(recentReviews, RECENT_RECORDS_LIMIT)
        );

        localStorage.setItem(
          "reviewedRecords",
          JSON.stringify(reviewedRecords)
        );
      });
      const promiseUnreviewed = getRecords(false).then((unreviewedRecords) => {
        setUnreviewed(unreviewedRecords);

        localStorage.setItem(
          "unreviewedRecords",
          JSON.stringify(unreviewedRecords)
        );
      });

      return Promise.all([promiseReviewed, promiseUnreviewed]);
    }

    try {
      const { clearCache, cacheExpiry } = getCacheStatus();

      if (cacheExpiry < new Date() || clearCache) {
        localStorage.clear();
        setDataAndCache().then(() => {
          const now = new Date();
          localStorage.setItem(
            "cacheExpiry",
            now.setDate(now.getDate() + 1).toString()
          );
        });
        return;
      }
      const cachedReviewedRecords = JSON.parse(
        localStorage.getItem("reviewedRecords")!
      );
      const cachedUnreviewedRecords = JSON.parse(
        localStorage.getItem("unreviewedRecords")!
      );
      if (!cachedReviewedRecords || !cachedUnreviewedRecords) {
        setDataAndCache();
        return;
      }

      setReviews(cachedReviewedRecords);
      const recentReviews = cachedReviewedRecords.filter(
        (record: Record) => record.recent
      );
      signalIncorrectNumberOfElements(recentReviews, RECENT_RECORDS_LIMIT);
      setRecentReviews(getFirstNElements(recentReviews, RECENT_RECORDS_LIMIT));
      setUnreviewed(cachedUnreviewedRecords);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    const homeTabs: Tab[] = [
      {
        id: "reviews",
        active: true,
        header: <TabHeader type="text" text="Reviews" />,
        body: (
          <ReviewGrid
            records={reviews}
            showSearch={true}
            sortOptions={SORT_OPTIONS_WITH_RATING}
            onSelectRecord={onSelectRecord}
          />
        ),
      },
      {
        id: "records",
        active: false,
        header: <TabHeader type="text" text="To be reviewed" />,
        body: (
          <ReviewGrid
            records={unreviewed}
            showSearch={true}
            sortOptions={PERMANENT_SORT_OPTIONS}
            onSelectRecord={onSelectRecord}
          />
        ),
      },
      {
        id: "posts",
        active: false,
        header: <TabHeader type="text" text="Posts" />,
        body: <Posts />,
      },
    ];
    setTabs(homeTabs);
  }, [reviews, unreviewed]);

  return (
    <>
      <div className={styles.container}>
        <LandingPage recent={recentReviews} onSelectRecord={onSelectRecord} />
        <div className="spacing"></div>
        <Tabs tabs={tabs} onSetSelectedTab={setSelectedTab}>
          {tabs.find((tab) => tab.active)?.body}
        </Tabs>
        {showModal && (
          <Modal onHideModal={handleHideModal}>
            <RecordProfile record={selectedRecord}></RecordProfile>
          </Modal>
        )}
      </div>
      <div className="footer">
        <h5>
          Made by{" "}
          <a id="me" href="https://www.last.fm/user/nunomrgomes">
            me
          </a>
          . Opinions are my own.
        </h5>
      </div>
    </>
  );
}

export default Home;
