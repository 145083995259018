function ComingSoon() {
  return (
    <div
      style={{
        margin: "auto",
        marginTop: "5em",
        width: "80%",
        textAlign: "center",
        height: "45vh",
      }}
    >
      <h2>More coming soon!</h2>
    </div>
  );
}

export default ComingSoon;
