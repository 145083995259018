export const sanitize = (str: string) =>
  str
    .replace(/’/g, "'")
    .replace(/‐/g, "-")
    .replace(
      /(\(feat\..*\)|\[feat\..*\]|feat\..*|\(ft\..*\)|\[ft\..*\]|ft\..*|\(Feat\..*\)|\[Feat\..*\]|Feat\..*|\(Ft\..*\)|\[Ft\..*\]|Ft\..*|FEAT\..*).*/,
      ""
    );

export const sanitizeArtist = (str: string) => {
  if (
    [
      "King Gizzard & The Lizard Wizard",
      "King Gizzard And The Lizard Wizard",
    ].includes(str)
  ) {
    return "King Gizzard & The Lizard Wizard";
  }

  if (["Bruno Mars, Anderson .Paak & Silk Sonic", "Silk Sonic"].includes(str)) {
    return "Bruno Mars, Anderson .Paak, Silk Sonic";
  }

  return sanitize(str)
    .replace(/(,[a-zA-Z\s]*&.*|\s[&xX]\s.*)/, "")
    .trim();
};
export const sanitizeTrack = (str: string) =>
  sanitize(str)
    .replace(
      /(-\s*interlude|\(interlude\)|-\s*reprise|\(reprise\)|-\s*remix|\(remix\)|remix|-\s*Interlude|\(Interlude\)|-\s*Reprise|\(Reprise\)|-\s*Remix|\(Remix\)|Remix|REMIX|\s-\sSingle|\(Single\|\(extended version\)|\(Extended\)|\[Extended\]|\(.* Sample Removed\)|\(Explicit\)|\[Explicit\]|\(explicit\)|\[explicit\]|-\s*Album\sversion|-\s*Album\sVersion).*/,
      ""
    )
    .replace(",", "")
    .trim();

export const sanitizeAlbum = (str: string) =>
  str
    .replace(" (Deluxe Version)", "")
    .replace(" (Deluxe Edition)", "")
    .replace(" (The Remaster)", "")
    .replace(" - The Remaster", "")
    .replace(" (The Songs)", "");
