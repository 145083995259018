// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  DocumentData,
  query,
  where,
  doc,
  getDoc,
  DocumentReference,
} from "firebase/firestore";

import { Record, Artist } from "../model/database";
import { setArtistUrls, setRecordUrls } from "./utils";

enum RecordTypes {
  LP = "FHoKKizSnXVZ7oXkuwj0",
  EP = "uEWDbAB9bQ1Y1jNaoRAN",
  Single = "lcHTj3Dyc28GxnuzrJGb",
  Mixtape = "sLZgYmSB4V2EY32rKo2c",
  Soundtrack = "rtiNaFRg9BeRCpYcTHbt",
}
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Initialize Firebase
initializeApp({
  apiKey: "AIzaSyCm-J8lZOtMOg8wSzZyyh1Cm-c0MohJXNs",
  authDomain: "music-reviews-nunogomes.firebaseapp.com",
  projectId: "music-reviews-nunogomes",
  storageBucket: "music-reviews-nunogomes.appspot.com",
  messagingSenderId: "899323499452",
  appId: "1:899323499452:web:5cc165b4f3c1707d3b91b9",
});

const db = getFirestore();

export async function getRecords(isReviewed = true): Promise<Record[]> {
  const documentQuery = query(
    collection(db, "records"),
    where("review", isReviewed ? ">" : "<=", "")
  );

  let recordsReference;
  try {
    recordsReference = await getDocs(documentQuery);
  } catch (e) {
    console.log("Error getting records", e);
  }
  if (!recordsReference?.docs.length) throw new Error("No records found");

  return await Promise.all(
    recordsReference.docs.map(async (recordDocument: DocumentData) => {
      let record: Record = { id: recordDocument.id, ...recordDocument.data() };
      record = setRecordUrls(record);
      try {
        record.artists = await getArtistsByRecordReference(recordDocument.ref);
      } catch (e) {
        console.log(`Error getting artist for record with id ${record.id}`, e);
      }
      return record;
    })
  );
}

// Currently unused
export async function getLPs(isReviewed = false): Promise<Record[]> {
  let recordsSnapshot;
  const lpReference = doc(db, "record_type", RecordTypes.LP);
  try {
    const segments = [where("record_type", "==", lpReference)];
    isReviewed &&
      segments.push(where("review", "!=", null)) &&
      segments.push(where("review", ">", ""));

    recordsSnapshot = await getDocs(
      query(collection(db, "records"), ...segments)
    );
  } catch (e) {
    console.log("Error getting records", e);
  }
  if (!recordsSnapshot?.docs.length) throw new Error("No records found");

  return await Promise.all(
    recordsSnapshot.docs.map(async (recordDocument: DocumentData) => {
      let record: Record = { id: recordDocument.id, ...recordDocument.data() };
      record = setRecordUrls(record);
      try {
        record.artists = await getArtistsByRecordReference(recordDocument.ref);
      } catch (e) {
        console.log(`Error getting artist for record with id ${record.id}`, e);
      }
      return record;
    })
  );
}

export async function getArtistsByRecordReference(
  recordReference: DocumentReference
): Promise<Artist[]> {
  const recordArtists = await getDocs(
    query(
      collection(db, "record_artist"),
      where("record_id", "==", recordReference)
    )
  );
  return Promise.all(
    recordArtists.docs.map(async (recordArtistDocument) => {
      const artistReference = recordArtistDocument.data().artist_id;
      const artistDocument = await getDoc(artistReference);
      const artist = setArtistUrls(artistDocument.data() as Artist);
      return { ...artist, id: artistReference.id };
    })
  );
}

export async function getRecordsByArtistId(artistId: string): Promise<any> {
  let recordArtistReference;
  try {
    const artistReference = doc(db, "artists", artistId);
    recordArtistReference = await getDocs(
      query(
        collection(db, "record_artist"),
        where("artist_id", "==", artistReference)
      )
    );
  } catch (e) {
    console.log("Error getting records", e);
  }
  if (!recordArtistReference?.docs.length) throw new Error("No records found");

  return Promise.all(
    recordArtistReference.docs.map(async (recordArtistDocument) => {
      const recordReference = recordArtistDocument.data().record_id;
      const record = setRecordUrls(
        (await getDoc(recordReference)).data() as Record
      );
      const authors = await getArtistsByRecordReference(recordReference);

      return {
        ...record,
        id: recordReference.id,
        artists: authors,
      };
    })
  );
}

export async function getRecordById(recordId: string): Promise<Record> {
  const recordReference = doc(db, "records", recordId);
  let record = (await getDoc(recordReference)).data() as Record;
  if (!record) throw new Error("Record not found");

  record = setRecordUrls(record);
  try {
    record.artists = await getArtistsByRecordReference(recordReference);
  } catch (e) {
    console.log(`Error getting artist for record with id ${recordId}`, e);
  }
  return record;
}

export async function getArtistById(artistId: string): Promise<Artist> {
  const artistReference = doc(db, "artists", artistId);
  let artist = (await getDoc(artistReference)).data() as Artist;
  if (!artist) throw new Error("Record not found");

  artist = setArtistUrls(artist);
  return artist;
}
