import { useState } from "react";

interface SearchInputProps {
  value: string;
  placeholder?: string;
  label?: string;
  flexRow?: boolean;
  onSetFilter: (value: string) => any;
}

function SearchInput(props: SearchInputProps) {
  const { placeholder, label, flexRow } = props;
  const [value, setValue] = useState(props.value);

  return (
    <div style={{ display: "flex", flexDirection: flexRow ? "row" : "column" }}>
      <label>{label}</label>
      <input
        type="text"
        placeholder={placeholder}
        onChange={(e) => {
          setValue(e.target.value);
          props.onSetFilter(e.target.value || "");
        }}
        value={value}
      />
    </div>
  );
}

export default SearchInput;
