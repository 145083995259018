import { ChangeEvent, useState } from "react";
import { DropdownOption } from "../model/components";

import "../styles/ui-controls/Dropdown.module.css";

interface DropdownProps {
  options: DropdownOption[];
  value: string;
  name?: string;
  label?: string;
  flexRow?: boolean;
  onChange: (e: ChangeEvent<HTMLSelectElement>) => any;
}

function Dropdown(props: DropdownProps) {
  const { options, name, label, flexRow } = props;
  const [value, setValue] = useState(props.value);
  return (
    <div style={{ display: "flex", flexDirection: flexRow ? "row" : "column" }}>
      <label>{label}</label>
      <select
        name={name}
        id={`${name}-input`}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          props.onChange(e);
        }}
      >
        {options.map((option: DropdownOption) => {
          return (
            <option value={option.value} key={option.value}>
              {option.text}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default Dropdown;
