import styles from "../styles/ui-controls/Modal.module.css";

interface ModalProps {
  children: any;
  onHideModal: (e: any) => void;
}

function Modal(props: ModalProps) {
  const { children } = props;
  return (
    <div className={`${styles.mask}`} onClick={props.onHideModal}>
      <div className={styles.container}>
        <i className={`mdi mdi-close ${styles.close}`} />
        {children}
      </div>
    </div>
  );
}

export default Modal;
