function Post(props: PostProps) {
  const { title, subtitle, children } = props;
  return (
    <article>
      <h2>{title}</h2>
      <h4>{subtitle}</h4>
      {children}
    </article>
  );
}

interface PostProps {
  title: string;
  subtitle?: string;
  children?: any;
}

export default Post;
