interface MedalIcon {
  name: string;
  color: string;
}

export class Medal {
  label: string;
  description: string;
  icon: MedalIcon;
  constructor(label: string, description: string, icon: MedalIcon) {
    this.label = label;
    this.description = description;
    this.icon = icon;
  }
}

export const Medals = {
  shame: new Medal("Shame", "At least one 0/10 rating", {
    name: "trophy-broken",
    color: "red",
  }),
  "magnum-opus": new Medal("Magnum Opus", "At least one 10/10 rating", {
    name: "crown",
    color: "gold",
  }),
  "on-a-roll": new Medal("On a Roll", "3 consecutive records over 7.0", {
    name: "trending-up",
    color: "forestgreen",
  }),
  "hall-of-fame": new Medal("Hall Of Fame", "5 consecutive records over 7.0", {
    name: "trophy-award",
    color: "gold",
  }),
  "hot-and-cold": new Medal(
    "Hot and Cold",
    "Has a record rated 7.0 or higher and a record rated 3.0 or lower.",
    {
      name: "scale-unbalanced",
      color: "white",
    }
  ),
  "under-the-water": new Medal("Under the Water", "All negative reviews", {
    name: "swim",
    color: "cyan",
  }),
  "strong-start": new Medal("Strong Start", "Debut record rated over 7.0", {
    name: "album",
    color: "cyan",
  }),
  "rough-start": new Medal("Rough Start", "Debut record rated under 3.0", {
    name: "sign-caution",
    color: "cyan",
  }),
};
