import * as Regex from "./regex";

const API_KEY = "b821fb7e1be85e09741fc8e98983f91e";

export interface Track {
  name: string;
  artist: string;
  playcount: number;
  duration: number;
}

export interface Artist {
  name: string;
}

export async function getMyTracks(): Promise<Track[]> {
  const response = await fetch(
    `https://ws.audioscrobbler.com/2.0/?method=user.gettoptracks&user=nunomrgomes&api_key=${API_KEY}&format=json&limit=1000`
  ).then(async (response: any) => {
    return await response.json();
  });

  const N_PAGES = response.toptracks["@attr"].totalPages;
  const tracks = response.toptracks.track;

  for (let i = 2; i <= N_PAGES; i++) {
    const response = await fetch(
      `https://ws.audioscrobbler.com/2.0/?method=user.gettoptracks&user=nunomrgomes&api_key=${API_KEY}&format=json&limit=1000&page=${i}`
    ).then(async (response: any) => {
      return await response.json();
    });

    tracks.push(...response.toptracks.track);
  }

  return tracks.map((track: any) => ({
    name: Regex.sanitizeTrack(track.name),
    artist: Regex.sanitizeArtist(track.artist.name),
    playcount: track.playcount,
    duration: Number(track.duration) | 150,
  }));
}
