import Card from "../ui-controls/Card";

import { Record } from "../model/database";

import styles from "../styles/LandingPage.module.css";

function LandingPage(props: LandingPageProps) {
  const { recent } = props;

  return (
    <div className={styles.container}>
      <div className={styles.logo}>
        <div className={styles["logo-title"]}>
          <h1>What I Think of Music.</h1>
        </div>
        <div className="icon-container">
          <svg
            fill="white"
            width="100%"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M18.586 10.081c-1.439 0-1.35 2.802-2.029 4.071-.114.211-.425.184-.5-.044-.771-2.364-.419-8.108-2.51-8.108-2.189 0-1.648 7.433-2.5 10.462-.063.23-.381.25-.474.028-.9-2.161-.799-6.875-2.502-6.875-1.762 0-1.612 3.949-2.302 5.54-.091.213-.392.22-.493.01-.503-1.049-.664-3.165-2.564-3.165h-2.213c-.275 0-.499.224-.499.499s.224.501.499.501h2.213c1.572 0 1.038 3.484 2.854 3.484 1.684 0 1.502-3.79 2.223-5.47.088-.208.382-.202.466.006.805 2.047.79 6.98 2.641 6.98 2.077 0 1.337-7.856 2.443-10.621.083-.211.384-.222.479-.012 1.029 2.25.487 8.126 2.344 8.126 1.639 0 1.737-2.706 2.23-4.038.081-.212.373-.227.474-.027.516 1.001.846 2.572 2.4 2.572h2.235c.275 0 .499-.224.499-.499 0-.276-.224-.5-.499-.5h-2.235c-1.323 0-1.117-2.92-2.68-2.92z" />
          </svg>
        </div>
      </div>
      <div className={styles.recent}>
        <h2 className={styles["grid-title"]}>Highlighted reviews</h2>
        {recent.map((record: Record) => (
          <Card
            key={record.id}
            image={{
              source: record.cover_art,
              alt: `${record.name} - ${record.artists
                .map((artist) => artist.name)
                .join(" & ")}`,
            }}
            leftBadge={record.release_year}
            rightBadge={`${record.rating}/10`}
            onClick={() => props.onSelectRecord(record)}
          />
        ))}
      </div>
    </div>
  );
}

interface LandingPageProps {
  recent: Record[];
  onSelectRecord: (record: Record) => void;
}

export default LandingPage;
