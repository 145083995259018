import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { HelmetProvider, Helmet } from "react-helmet-async";
import Home from "./views/Home";
import RecordProfile from "./views/RecordProfile";
import ArtistProfile from "./views/ArtistProfile";

import "./styles/index.css";

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <Helmet>
        <title>What I Think of Music</title>
        <meta name="description" content="My opinion of music." />
        <meta property="og:title" content="What I Think Of Music" />
        <meta property="og:description" content="My opinion of music." />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="720" />
        <meta property="og:image" content="/preview.png" />
        <meta property="og:url" content="https://whatithinkofmusic.com" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/album/:id" element={<RecordProfile />} />
          <Route path="/artist/:id" element={<ArtistProfile />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </Router>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
