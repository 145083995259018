import { useEffect, useState } from "react";
import { getMyTracks } from "../../lib/lastfm";
import Post from "../Post";

function Playtime() {
  const [playtime, setPlaytime] = useState(0);
  const [numberOfTracks, setNumberOfTracks] = useState(0);
  const [numberOfArtists, setNumberOfArtists] = useState(0);
  useEffect(() => {
    getMyTracks().then((myTracks) => {
      setNumberOfTracks(myTracks.length);

      const artistNames: string[] = [];
      myTracks.forEach((track) => {
        if (!artistNames.includes(track.artist)) {
          artistNames.push(track.artist);
        }
      });

      setNumberOfArtists(artistNames.length);

      const playtimeFloat =
        myTracks.reduce(
          (playtime, { duration, playcount }) =>
            playtime + duration * playcount,
          0
        ) / 3600;

      setPlaytime(parseInt(playtimeFloat.toString()));
    });
  }, []);

  return (
    <Post title="How much music I have heard">
      <p>
        {playtime === 0 ? (
          "🤖 Calculating in real time 🤖"
        ) : (
          <>
            {" "}
            Since I started tracking in 01-01-2022, I have heard{" "}
            <strong>{playtime}</strong> hours of music.{" "}
            <strong>{numberOfTracks}</strong> different tracks from{" "}
            <strong>{numberOfArtists}</strong> different artists.
          </>
        )}
      </p>
    </Post>
  );
}

export default Playtime;
