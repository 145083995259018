function TabHeader(props: any) {
  const { type, text, icon } = props;

  return (
    <>
      {(type === "text" || !type) && <h3>{text}</h3>}
      {type === "icon" && <i className={`mdi mdi-${icon}`} />}
    </>
  );
}

export default TabHeader;
