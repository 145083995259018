interface Image {
  source: string;
  alt: string;
}

interface CardProps {
  image: Image;
  leftBadge?: string | number;
  rightBadge?: string | number;
  onClick?: () => void;
}

function Card(props: CardProps) {
  const { image, leftBadge, rightBadge } = props;

  return (
    <div className="card" onClick={props.onClick}>
      <img src={image.source} alt={image.alt} />
      {leftBadge && <div className="left-badge">{leftBadge}</div>}
      {rightBadge && <div className="right-badge">{rightBadge}</div>}
    </div>
  );
}

export default Card;
