import { Playtime, WorstBars } from "./posts/";
import ComingSoon from "../components/ComingSoon";

function Posts() {
  const container = {
    width: "80%",
    margin: "auto",
    display: "flex",
    gap: "2em",
  };

  return (
    <div style={{ ...container, flexDirection: "column" }}>
      <Playtime />
      <WorstBars />
      <ComingSoon />
    </div>
  );
}

export default Posts;
