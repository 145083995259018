import { Medals as PossibleMedals } from "../model/medals";

import { Record } from "../model/database";

import styles from "../styles/Medals.module.css";

function Medals(props: MedalProps) {
  const { records } = props;

  const medalRecords = records.filter((r) => r.review);

  if (!medalRecords.length) {
    return <></>;
  }

  const medals = [];

  const debut = medalRecords.find((r) => r.debut);
  if (debut) {
    debut.rating > 7 && medals.push(PossibleMedals["strong-start"]);
    debut.rating < 3 && medals.push(PossibleMedals["rough-start"]);
  }

  if (medalRecords.some((record) => record.rating === 10)) {
    medals.push(PossibleMedals["magnum-opus"]);
  }

  if (medalRecords.some((record) => record.rating === 0)) {
    medals.push(PossibleMedals.shame);
  }

  if (medalRecords.every((record) => record.rating < 5)) {
    medals.push(PossibleMedals["under-the-water"]);
  }

  if (
    medalRecords.find((record) => record.rating <= 3) &&
    medalRecords.find((record) => record.rating >= 7)
  ) {
    medals.push(PossibleMedals["hot-and-cold"]);
  }

  const chronologicalRecords = records.sort((a, b) =>
    a.release_year > b.release_year ? 1 : -1
  );

  let streak = 0,
    maxStreak = 0;

  for (let i = 0; i < chronologicalRecords.length; i++) {
    streak = chronologicalRecords[i].rating >= 7 ? streak + 1 : 0;
    maxStreak = Math.max(streak, maxStreak);
  }

  if (maxStreak >= 5) {
    medals.push(PossibleMedals["hall-of-fame"]);
  } else if (maxStreak >= 3) {
    medals.push(PossibleMedals["on-a-roll"]);
  }

  return (
    <div className={styles.container}>
      {Object.entries(medals).map(([id, { label, description, icon }]) => (
        <div
          key={id}
          className={styles["medal-wrapper"]}
          title={`${label} - ${description}`}
        >
          <i className={`mdi mdi-${icon.name} ${styles.icon}`} />
        </div>
      ))}
    </div>
  );
}

interface MedalProps {
  records: Record[];
}

export default Medals;
