function Review(props: any) {
  const { review } = props;
  return (
    <>
      <hr />
      <div
        className="review"
        dangerouslySetInnerHTML={{
          __html: review,
        }}
      />
    </>
  );
}

export default Review;
