import { Artist, Record } from "../model/database";
import { JSONObject } from "../model/types";
import { addEllipsis, removeExtraWhitespaces, truncate } from "./strings";

import { compare } from "./types";

const ART_URL = "https://i.scdn.co/image/";
const SPOTIFY_RECORD_URL =
  "https://open.spotify.com/embed/album/{record_id}?utm_source=generator&theme=0";
const SPOTIFY_ARTIST_URL = "https://open.spotify.com/artist/";
const APPLE_RECORD_URL = "https://embed.music.apple.com/pt/album/";
const APPLE_ARTIST_URL = "https://music.apple.com/pt/artist/";
const YOUTUBE_RECORD_URL = "https://www.youtube.com/embed/videoseries?list=";
const YOUTUBE_ARTIST_URL = "https://www.youtube.com/channel/";

export function recordFilter(record: Record, filter: string): boolean {
  const { name, artists } = record;
  return (
    name.toLowerCase().includes(filter.toLowerCase()) ||
    artists
      .reduce((full, current) => (full += current.name), "")
      .toLowerCase()
      .includes(filter.toLowerCase())
  );
}

export function recordSorter(
  recordA: Record,
  recordB: Record,
  field: string,
  invertingFactor: number
): number {
  let a, b;
  if (field === "artists") {
    a = joinArtistNames(recordA);
    b = joinArtistNames(recordB);
  } else {
    a = getField(recordA, field);
    b = getField(recordB, field);
  }

  return invertingFactor * compare(a, b);
}

export function setRecordUrls(record: Record): Record {
  return {
    ...record,
    cover_art: `${ART_URL}${record.cover_art}`,
    spotify_id: record.spotify_id
      ? SPOTIFY_RECORD_URL.replace("{record_id}", record.spotify_id)
      : "",
    apple_id: record.apple_id ? `${APPLE_RECORD_URL}${record.apple_id}` : "",
    youtube_id: record.youtube_id
      ? `${YOUTUBE_RECORD_URL}${record.youtube_id}`
      : "",
  };
}

export function setArtistUrls(artist: Artist): Artist {
  return {
    ...artist,
    picture: artist.picture ? `${ART_URL}${artist.picture}` : "",
    spotify_id: artist.spotify_id
      ? `${SPOTIFY_ARTIST_URL}${artist.spotify_id}`
      : "",
    apple_id: artist.apple_id ? `${APPLE_ARTIST_URL}${artist.apple_id}` : "",
    youtube_id: artist.youtube_id
      ? `${YOUTUBE_ARTIST_URL}${artist.youtube_id}`
      : "",
  };
}

export const joinArtistNames = (r: Record): string =>
  r.artists.map((a: Artist) => a.name).join();

const getField = (r: JSONObject, field: string) => r[field];

export function getCacheStatus() {
  const urlParams = new URLSearchParams(window.location.search);
  const clearCache = urlParams.get("clearCache");
  const cacheExpiry = new Date(Number(localStorage.getItem("cacheExpiry")!));

  if (clearCache) {
    window.location.href = window.location.href.split("?")[0];
  }

  return { clearCache: clearCache === "true", cacheExpiry };
}

export function createPreviewText(text: string): string {
  const innerText = removeExtraWhitespaces(text.replace(/\n/g, " "));
  let previewText = truncate(innerText);
  if (innerText.length > 140) {
    previewText = addEllipsis(previewText);
  }
  return previewText;
}
