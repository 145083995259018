export function truncate(text: string, maxLength: number = 140): string {
  return text.slice(0, maxLength).trim();
}

export function addEllipsis(text: string) {
  return text.replace(/\s+\S*$/, " (...)");
}

export function removeExtraWhitespaces(text: string): string {
  return text.replace(/\s+/g, " ");
}
