export function compare(a: Number, b: Number): number;
export function compare(a: string, b: string): number;
export function compare(a: any, b: any): number {
  if (typeof a === "number" && typeof b === "number") {
    return b - a;
  }

  return a.localeCompare(b);
}

export function getFirstNElements(array: any[], n: number): any[] {
  return array.filter((_, i) => i < n);
}

export function signalIncorrectNumberOfElements(array: any[], n: number): void {
  array.length !== n &&
    console.log(`${array.length} recent review found.`, array);
}
