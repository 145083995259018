import Post from "../Post";

function WorstBars() {
  return (
    <Post
      title="A list of the worst bars I have heard from popular hip-hop artists"
      subtitle="This is a constantly expanding list of the most laughably bad bars I've
    heard from popular rappers:"
    >
      <ul>
        <li>
          "You got something to say, then say that then." - Drake,{" "}
          <strong>Now & Forever</strong> in{" "}
          <strong>If You're Reading This It's Too Late</strong>
        </li>
        <li>
          "I always had a Ph.D: a pretty huge dick" - Kanye West,{" "}
          <strong>Breathe In Breathe Out</strong> in{" "}
          <strong>College Dropout</strong>
        </li>
        <li>
          "A journalist can get a mouthful of flesh. And yes, I mean eating a
          penis" - Eminem, <strong>The Ringer</strong> in{" "}
          <strong>Kamikaze</strong>
        </li>
        <li>
          "Say that you a lesbian, girl, me too" - Drake,{" "}
          <strong>Girls Want Girls</strong> in{" "}
          <strong>Certified Lover Boy</strong>
        </li>
        <li>
          "I know folk, I know people. I'm the shit, I go fecal" - Killer Mike,{" "}
          <strong>TALK'N THAT SHIT</strong> in <strong>MICHAEL</strong>
        </li>
        <li>
          "Rape and pillage your village, women and children // Everybody want
          to know what my Achilles heel is // Love, I don't get enough of it" -
          Jay Z, on Kanye West's <strong>Monster</strong> in{" "}
          <strong>My Beautiful Dark Twisted Fantasy</strong>
        </li>
        <li>...</li>
      </ul>
    </Post>
  );
}

export default WorstBars;
