import { Tab } from "../model/components";

import styles from "../styles/ui-controls/Tabs.module.css";

function Tabs(props: any) {
  const { tabs, children } = props;

  return (
    <>
      <div className={styles.headers}>
        {tabs.map((tab: Tab) => {
          const { id, active, header } = tab;
          return (
            <div
              key={id}
              className={`tab-header${active ? "-selected" : ""}`}
              onClick={() => props.onSetSelectedTab(id)}
            >
              {header}
            </div>
          );
        })}
      </div>
      <div className={styles.content}>{children}</div>
    </>
  );
}

export default Tabs;
