import SearchInput from "../ui-controls/SearchInput";
import Dropdown from "../ui-controls/Dropdown";
import Card from "../ui-controls/Card";

import { useEffect, useState } from "react";
import { Record } from "../model/database";
import { recordFilter, recordSorter } from "../lib/utils";

import styles from "../styles/ReviewGrid.module.css";

function ReviewGrid(props: ReviewGridProps) {
  const { records, showSearch, sortOptions } = props;
  const [filteredRecords, setFilteredRecords] = useState(records);
  const [filter, setFilter] = useState("");
  const [sort, setSort] = useState("rating");
  const [ascFactor, setAscFactor] = useState(1);
  const [columns, setColumns] = useState(1);

  function updateFilteredRecords() {
    setFilteredRecords(
      records
        .sort((recordA, recordB) =>
          recordSorter(recordA, recordB, sort, ascFactor)
        )
        .filter((record) => recordFilter(record, filter))
    );
  }

  useEffect(updateFilteredRecords, [records, sort, filter, ascFactor]);


  // Function to calculate columns based on window width
  const calculateColumns = () => {
    const numColumns = Math.max(Math.floor(window.innerWidth / 500), 1); // Ensure at least 1 column
    setColumns(numColumns);
  };

  // useEffect to run on component mount and on window resize
  useEffect(() => {
    calculateColumns(); // Initial calculation

    // Add event listener for window resize
    window.addEventListener('resize', calculateColumns);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', calculateColumns);
    };
  }, []);

  return (
    <div className={styles.container}>
      {(showSearch || sortOptions?.length) && (
        <div className={styles.filters}>
          {showSearch && (
            <div className={styles.filter}>
              <SearchInput
                placeholder={`Filter records by name or artist name out of ${records.length} results`}
                value={filter}
                onSetFilter={setFilter}
              />
            </div>
          )}
          {sortOptions?.length && (
            <div className={styles.sort}>
              <button
                title="Invert Sort Order"
                className={styles.invert}
                onClick={() => setAscFactor(-ascFactor)}
              >
                <i
                  className={`mdi mdi-sort-bool-${
                    ascFactor === -1 ? "ascending" : "descending"
                  }`}
                />
              </button>
              <Dropdown
                options={sortOptions}
                name="sort-by"
                value={sort}
                onChange={(e) => {
                  setSort(e.target.value);
                }}
              />
            </div>
          )}
        </div>
      )}
      <div
        className={styles.grid}
        style={{gridTemplateColumns: `repeat(${window.navigator.userAgent.includes("Firefox") ? columns : "auto-fit"}, minmax(20em, 1fr))`}}
      >
        {filteredRecords.map((record: Record) => {
          let rightBadge;
          if (record.review) {
            rightBadge = `${record.rating}/10`;
          }

          return (
            <Card
              key={record.id}
              image={{
                source: record.cover_art,
                alt: `${record.name} - ${record.artists
                  .map((artist) => artist.name)
                  .join(" & ")}`,
              }}
              leftBadge={record.release_year}
              rightBadge={rightBadge}
              onClick={() =>
                props.onSelectRecord && props.onSelectRecord(record)
              }
            />
          );
        })}
      </div>
    </div>
  );
}

interface ReviewGridProps {
  records: Record[];
  showSearch: boolean;
  sortOptions?: { text: string; value: string }[];
  onSelectRecord: (record: Record) => void;
}

export default ReviewGrid;
